<template lang="pug">
.crypto-slot(v-if="tokenId")
  header
    h1 Crypto Slot &#35;{{tokenId}}
  iframe(:src="'https://utility.workshop.blue/t/cs-v1/'+tokenId", :width="DIM", :height="DIM", frameborder="0")

</template>

<script>

export default {
  name: 'EmojiSlotsView',
  components: {
  },
  data () {
    return {
      tokenId: null,
      DIM: 100
    }
  },
  beforeMount () {
    let WIDTH = window.innerWidth
    let HEIGHT = window.innerHeight
    let DIM = Math.min(WIDTH, HEIGHT)
    this.DIM = DIM * .9

    this.tokenId = this.$route.params.tokenId

    fetch(`https://utility.workshop.blue/t/cs-v1/${this.$route.params.tokenId}/data`)
    .then(response => response.json())
    .then(data => {
      console.log(data)
    })
  }
}
</script>

<style lang="stylus" scoped>
$color-black = #2C2C2C
$color-white = #E8E8D9

.crypto-slot
  background-color $color-white
  height 100%
  max-height 100%
  overflow hidden
  display flex
  flex-direction column
  justify-content center
  align-items center
</style>
