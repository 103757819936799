<template lang="pug">
  .emoji-slots
    section.section-1
      header
        .max-width
          nav
            .left
              a(href="#roadmap") Roadmap
              a(href="#wut") FAQ
              a(href="#team") Team
            .right
              a(href="https://discord.gg/TETDBgJVrm", target="_blank")
                img(src="/img/discord.svg")
              a(href="https://twitter.com/workshopblueNFT", target="_blank")
                img(src="/img/twitter.svg")
              .wallet
                button.button.green.connected(v-if="wallet && wallet.address") {{ formattedWalletAddress }}
                button.button.green(type="button", v-else, @click="connectWallet") Connect Wallet
        .banner.center(v-if="!validNetworkHide")
          p Invalid MetaMask network. Please switch to Ethereum Mainnet.

      .logo 🎰 Crypto Slots
      .logo-spacer.max-width
        .row
          .col
            .background.text.row
              .col
                p Crypto Slots are a collection of 777 dynamic slot machine NFTs whose outcome is seeded by the last transaction hash of the current owner. This means the slot machine will change with each transaction encouraging trading! There will be 12 editions in varying rarity that will be available to mint including a contest for a community voted edition.
              .col.image
                img(src="../assets/images/edition-faces-animation.gif")

      .max-width.minting-wrapper(v-if="nftContract")
        .mint
          .minting {{minting.count}} @ {{formattedMintingCost}} ETH
          .buttons
            button.button.quantity.first(type="button", :class="{'selected': minting.count === 1}", @click="changeMinting(1)") 1
            button.button.quantity.second(type="button", :class="{'selected': minting.count === 2}", @click="changeMinting(2)") 2
            button.button.quantity.third(type="button", :class="{'selected': minting.count === 3}", @click="changeMinting(3)") 3
            button.button.green(type="button", @click="mint", :disabled="!wallet.address || pending")
              span(v-if="!pending") MINT
              span(v-else)
                img(src="../assets/images/loading-icon.gif", height="24", width="24")
          .minted {{mintCount}}/777 minted

          .transaction-message(v-if="transactionState", :class="transactionState")
            p {{transactionMessage}}

    section.section-2
      .max-width
        vueper-slides.no-shadow(
          :visible-slides="3",
          slide-multiple,
          :gap="3",
          :slide-ratio="1 / 4",
          :dragging-distance="200",
          :breakpoints="{ 800: { visibleSlides: 2, slideMultiple: 2 } }",
          fixedHeight="460px"
        )
          vueper-slide(v-for="(edition, i) in formattedEditions", :key="i")
            template(#content)
              .card-edition
                .edition
                  span {{ edition.n }} EDITION
                .emojis
                  .emoji(v-for="emoji in edition.c1") {{emoji}}
                .rarity
                  span {{ edition.r}}

    section.section-3(id="roadmap")
      .max-width
        h3 Roadmap
        .step.done
          .percentage
            span 0%
          .text
            p We are tired of generative jpgs with no intrinsic value. We have many big ideas forthcoming and owning a Crypto Slot will automatically whitelist you to pre-mint ALL future projects from the workshop.

        .step
          .percentage
            span 25%
          .text
            p Airdrop 5 crypto-slots to random HODLers.

        .step
          .percentage
            span 50%
          .text
            p Randomly give out .5 ETH to one owner's wallet.

        .step
          .percentage
            span 75%
          .text
            p Airdrop 10 crypto-slots to random HODLers.

        .step
          .percentage
            span 100%
          .text
            p Randomly give out 1 ETH to one owner's wallet<br>Create a DAO because that is what everyone does. Vote on things and stuff.

    section.section-3(id="wut")
      .max-width
        h3 FAQ
        .center
          VueFaqAccordion(:items="FAQItems", activeColor="#FFBF17", fontColor="#E8E8D9", borderColor="#E8E8D9")

    section.section4(id="team")
      .max-width
        h3 Team
        .team
          .member
            .emoji 🧑‍💻
            .name Brett
            .title Frontend
          .member
            .emoji 🕴️
            .name Swervo
            .title Backend
          .member
            .emoji 🤵‍♂️
            .name
              span GattisTV
              a.social(href="https://twitter.com/gattistv", target="_blank")
                img(src="/img/twitter.svg")
            .title Community
</template>

<script>

var Web3 = require('web3')
var web3 = new Web3(Web3.givenProvider)

const contractAddress = '0x58845Cb273819C1B1719BFd9C70C44901C5B5B46'
import ABI from '../assets/data/abi-crypto-slots'
//console.log(ABI)

// simple function that prints to the console the ETH balance, uses web3.utils to convert from WEI to ETH
const editions = [
  {n:'CONTEST', c1:['❓','❓','❓','❓','❓','❓'], r: '????'},
  {n:'FRUIT', c1:['🍏','🍌','🍒','🍉','🍇','🍐'], r: 'Common'},
  {n:'HEART', c1:['💗','🧡','💛','💚','💙','💔'], r: 'Common'},
  {n:'MUSIC', c1:['🎸','🥁','🎵','🎺','🎷','🎻'], r: 'Common'},
  {n:'ANIMAL', c1:['🐹','🐰','🐨','🐼','🐸','🐷'], r: 'Common'},
  {n:'FACES', c1:['😄', '🤣', '🥰', '😏', '😬', '🤯'], r: 'Common'},
  {n:'SPORT', c1:['🥇','⚽','⚾','🏀','🏈','🏒'], r: 'Uncommon'},
  {n:'FOOD', c1:['🍔','🍟','🍪','🍕','🌭','🌮'], r: 'Uncommon'},
  {n:'CAT', c1:['😻','😸','😹','😽','🙀','😿'], r: 'Uncommon'},
  {n:'BOOZE', c1:['🍾','🍺','🥃','🍷','🍸','🍹'], r: 'Rare'},
  {n:'GAMBLING', c1:['🎰','🎱','🃏','💰','🎲','😫'], r: 'Rare'},
  {n:'PANDEMIC', c1:['💉','😷','💊','🤢','🦠','💀'], r: 'Rare'},
  {n:'DIAMOND HANDS', c1:['💎','🙌','🚀','📈','🌛', '🤑'], r: 'Mythic'}
]

import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'
import VueFaqAccordion from 'vue-faq-accordion'

export default {
  name: 'App',
  components: {
    VueperSlides, VueperSlide, VueFaqAccordion
  },
  data () {
    return {
      pending: false,
      transactionState: null,
      wallet: { address:null},
      validNetworkHide: true,
      editions,
      nftContract: null,
      mintCount: 0,
      minting: {
        count: 1
      },
      FAQItems: [
        {
          title: 'What is a dynamic NFT?',
          value: 'A Dynamic NFT is a token that responds in some way to other conditions, either on-chain or off-chain. In this specific case it is last transaction hash.'
        },
        {
          title: 'Is there a pre-mint?',
          value: 'There is no pre-mint for this project.'
        },
        {
          title: 'When is public minting and reveal?',
          value: 'Public minting will be Monday, November 29th @ 3pm EST and reveal will be November 30th @ 3pm EST.'
        },
        {
          title: 'What is the cost per mint?',
          value: 'You can mint a maximum of 3 per wallet and the cost is 0.03 ETH per mint + gas.'
        },
        {
          title: 'What are the chances of a winning slot machine?',
          value: 'We are still tweaking the odds. Each matching set will have a different odds of winning. We want it to be rare but not too rare, ya know?'
        },
        {
          title: 'What\'s the CONTEST EDITION?',
          value: 'Discord members will get to create their own 6 emoji set. Then discord members will vote on their favorite AND the edition to replace. Winner will get 3 free Crypto Slots. Full contest rules can be found on our Discord channel.'
        },
        {
          title: 'But wait, if I get a winning spin won\'t that change next time I sell it?',
          value: 'Yup. That\'s the fun!'
        },
        {
          title: 'REALLY?',
          value: 'YES, okay, fine, maybe we can have the DOA vote on allowing a slot machine to be locked or something, but it will cost gas. And eventually every NFT will be a winner so you are no fun.'
        }
      ]
    }
  },
  metaInfo() {
    return {
      title: `workshop.blue - Crypto Slots`,
      meta: [
        {
          name: 'description',
          content: '777 interactive slot machine NFTs'
        }
      ]
    }
  },
  beforeMount () {
    document.addEventListener('scroll', this.logoChange)
    this.getContract()
    ethereum
      .request({method: 'eth_accounts'})
      .then((accounts) => {
        if (accounts.length === 0 || !this.validNetwork()) {
          console.log('Metamask not connected')
        } else {
          this.wallet = {
            address: accounts[0]
          }
          console.log('connected!')
        }
      })
    ethereum.on('chainChanged', this.handleChainChanged);
  },
  computed: {
    formattedEditions () {
      return this.editions.map(edition => ({
        ...edition,
        emojis: edition.c1.join(' ')
      }))
    },
    formattedMintingCost () {
      return this.minting.count * .03
    },
    formattedWalletAddress () {
      let text = this.wallet.address
      let start = text.substring(0, 5);
      let end = text.substring(text.length - 5, text.length);
      return start + '...' + end;
    },
    transactionMessage () {
      let message = `Successfully minted ${this.minting.count} Crypto Slots.`
      if (this.transactionState === 'error') {
        message = 'An error occured while minting. Please try again.'
      }
      return message
    }
  },
  methods: {
    handleChainChanged(_chainId) {
      // We recommend reloading the page, unless you must do otherwise
      //console.log(_chainId)
      window.location.reload();
    },
    validNetwork () {
      if(ethereum.networkVersion !== '1'){
        //console.log('INVALID NETWORK')
        this.validNetworkHide = false
        return false;
      }
      this.validNetworkHide = true
      return true
    },
    logoChange () {
      if (window.scrollY > 150 ) {
        document.querySelector("section.section-1").classList.add('logo-fixed')
      } else {
        document.querySelector("section.section-1").classList.remove('logo-fixed')
      }
    },
    async getContract () {
      if(this.validNetwork()){
        this.nftContract = await new web3.eth.Contract(ABI, contractAddress)
        this.mintCount = parseInt(await this.nftContract.methods.totalSupply().call())
      }
      //console.log(this.nftContract)
    },
    async connectWallet () {
      const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
      if(this.validNetwork()){
        this.wallet = {
          address: accounts[0]
        }
      }
    },
    async mint () {
      let that = this
      if(this.validNetwork()) {
        this.pending = true
        //console.log("mintCount before:", await this.nftContract.methods.totalSupply().call())
        const purchase = await this.nftContract.methods.mint(this.minting.count).send({
          from: this.wallet.address,
          gas: 250000,
          value: this.formattedMintingCost * 1000000000000000000
        })
        .on('transactionHash', function(hash){
          console.log('hash', hash)
        })
        .on('receipt', function(receipt){
            console.log('receipt', receipt)
        })
        .on('confirmation', function(confirmationNumber, receipt){
            that.pending = false
            that.transactionState = 'success'
            console.log('confirmation', confirmationNumber)
        })
        .on('error', function(error, receipt) {
            console.log('error', error)
            that.pending = false
            that.transactionState = 'error'
        });
        //console.log(purchase)
        //console.log("mintCount after:", await this.nftContract.methods.totalSupply().call())


        /*const transactionParameters = {
          nonce: '0x00', // ignored by MetaMask
          gasPrice: '0x09184e72a000', // customizable by user during MetaMask confirmation.
          gas: '0x2710', // customizable by user during MetaMask confirmation.
          to: '0x0000000000000000000000000000000000000000', // Required except during contract publications.
          from: ethereum.selectedAddress, // must match user's active address.
          value: '0x00', // Only required to send ether to the recipient from the initiating external account.
          data:
            '0x7f7465737432000000000000000000000000000000000000000000000000000000600057', // Optional, but used for defining smart contract creation and interaction.
          chainId: '0x3', // Used to prevent transaction reuse across blockchains. Auto-filled by MetaMask.
        };

        // txHash is a hex string
        // As with any RPC call, it may throw an error
        const txHash = await ethereum.request({
          method: 'eth_sendTransaction',
          params: [transactionParameters],
        });*/
      }
    },
    changeMinting (count) {
      this.minting.count = count
      this.transactionState = null
    }
    /*async getEthBalance (address) {
      await web3.eth.getBalance(address, (err, balance) => {
        //console.log(balance)
        //console.log(address + " Balance: ", web3.utils.fromWei(balance))
        this.jackpot = web3.utils.fromWei(balance)
      })
    }*/
  }
}
</script>

<style lang="stylus" scoped>

@import '../assets/styles/main.styl'

$color-black = #2C2C2C
$color-white = #E8E8D9
$color-red = #DE0607
$color-yellow = #FFBF17
$color-green = #009900

:root {
  --border-color: $color-white;
  --font-color: $color-white;
  --active-color $color-yellow;
}

.video
  border-radius 5px
  overflow hidden
  height 400px
  width 400px
  video
    object-fit cover

.transaction-message
  border-radius: 1rem
  color $color-white
  padding .1rem
  margin-top .5rem
  font-size 1rem
  &.error
    background-color: $color-red
  &.success
    background-color: $color-green

.banner
  background-color $color-red
  color $color-white
  padding 10px
  p
    padding 0
    margin 0

.center
  text-align center

.emoji-slots
  background-color $color-black
  color #E8E8D9
  font-size 1.5rem

.background
  background rgba(44,44,44,.8)
  border-radius 1rem
  color #fff
  .image
    padding 2rem
    img
      width 300px
      max-width 300px
.text
  p
    padding 2rem
    margin-bottom 2rem


@-webkit-keyframes glow {
  from {
    text-shadow: 0 0 10px #eeeeee, 0 0 20px #000000, 0 0 30px #000000, 0 0 40px #000000,
                 0 0 50px #9554b3, 0 0 60px #9554b3, 0 0 70px #9554b3;
  }
  to {
    text-shadow: 0 0 20px #eeeeee, 0 0 30px #ff4da6, 0 0 40px #ff4da6, 0 0 50px #ff4da6,
                 0 0 60px #ff4da6, 0 0 70px #ff4da6, 0 0 80px #ff4da6;
  }
}

@keyframes blink
  from
    color $color-white

  to
    color $color-yellow

.row
  display flex
  .col
    .background.text
      margin-right 2rem

@media only screen and (max-width: 600px)
  .row
    flex-direction column
    .col
      .background.text
        margin-right 0

.button
  background-color $color-black
  border none
  border-radius .5rem
  cursor pointer
  font-family inherit
  font-size inherit
  color $color-white
  display inline-block
  padding .5rem 1rem
  -webkit-appearance none
  -moz-appearance none
  &.green
    background-color $color-green
  &.quantity
    border-radius 0
    min-width 100px
    &.first
      border-radius .5rem 0 0 .5rem
      border-top 1px solid black
      border-bottom 1px solid black
      border-left 1px solid black
    &.second
      border-top 1px solid black
      border-bottom 1px solid black
    &.third
      border-top 1px solid black
      border-bottom 1px solid black
      border-right 1px solid black
      border-radius 0 .5rem .5rem 0
      margin-right 1rem

    &.selected
      border 3px solid $color-yellow

section
  padding-bottom 5rem

.card-edition
  background-color #444
  border-radius 1rem
  color $color-black
  padding 2rem 1rem
  .edition
    background-color $color-green
    border-radius 3rem
    color $color-white
    font-weight 500
    padding .5rem
    text-align center
  .emojis
    display flex
    flex-wrap wrap
    .emoji
      font-size 5rem
      padding 1.5rem 0
      text-align center
      width 33.333%
  .rarity
    color $color-white
    text-align center

.minting-wrapper
  text-align center
.mint
  background-color rgba(44,44,44,.8)
  border-radius .5rem
  display inline-block
  padding 2rem
  .minting
    border 1px solid black
    background-color $color-black
    border-radius .5rem
    margin-bottom .5rem
    padding 1rem
    font-size 2.5rem
    text-align center

  .green
    min-width 150px
    &:disabled
      opacity: .5
  .buttons
    margin-bottom .5rem
  .minted
    font-size 1rem
    text-align center

@media only screen and (max-width: 600px)
  .card-edition
    font-size 1.5rem
    .edition
      border-radius 3rem
      padding .5rem
    .emojis
      .emoji
        font-size 2rem
        padding 1rem 0
        width 50%
    .rarity
      color $color-white
      text-align center


.team
  display flex
  justify-content space-evenly
  .member
    text-align center
    .emoji
      background-color $color-green
      border 5px solid $color-yellow
      box-shadow inset 0 0 5px 1px $color-black
      border-radius 200px
      line-height 200px
      overflow hidden
      height 200px
      display inline-block
      width 200px
      font-size 10rem

    .social
      margin-left .5rem
      img
        width 20px

@media only screen and (max-width: 600px)
  .team
    flex-direction column
    .member
      margin-bottom 2rem

.step
  align-items center
  display flex
  &.done
    .percentage
      span
        background-color $color-red

  .percentage
    span
      background-color #444
      border-radius 100%
      display inline-block
      line-height 100px
      text-align center
      height 100px
      font-size 2rem
      width 100px
  .text
    flex 1

header
  background-color rgba(44,44,44,.8)
  text-align center
  position fixed
  width 100%
  z-index 100
  nav
    align-items center
    color $color-white
    display flex
    padding 1rem
    justify-content space-between
    font-size 1.5rem
    a
      color inherit
      display inline-block
      img
        vertical-align top
    .wallet
      display inline-block
      max-width 200px
      .connected
        overflow hidden
        width 100%
    .left, .right
      align-items center
      display flex
    .left
      a
        margin-right 2rem
    .right
      a, .wallet
        margin-left 2rem
  @media only screen and (max-width: 600px)
    nav
      justify-content end
      .left
        display none
      .right
        align-self right
        .wallet
          display none

.logo-spacer
  padding-top 460px
  padding-bottom 2rem


.logo
  animation glow 1s ease-in-out infinite alternate
  color $color-white
  font-family 'Casino'
  font-size 9rem
  text-align center
  margin 0 auto
  transition-duration .5s
  transition-property color, left, margin-left, font-size, width
  z-index 101

  position absolute
  top 150px
  left 50%
  margin-left -600px
  width 1200px

.logo-fixed
  header
    background rgba(44,44,44,1)
  .logo
    //animation none
    //color $color-green
    font-size 2.5rem
    position fixed
    top 0
    left 50%
    margin-left -180px
    padding-top .75rem
    width 360px

@media only screen and (max-width: 600px)
  .logo-spacer
    padding-top 100px
  .logo
    animation none
    position fixed
    font-size 2rem
    padding-top .75rem
    width 280px
    left -10px
    top 0
    margin-left 0
  .logo-fixed
    .logo
      font-size 2rem
      padding-top .75rem
      width 280px
      left -10px
      top 0
      margin-left 0

section
  &.section-1
    background-image url('../assets/images/jackpot-background.jpeg')
    background-size cover
    //padding-top 400px
    padding-bottom 2rem
    //padding 100px 0

  &.section-2
    //background-color #222
    //color #fff
    padding 3rem 0

</style>
